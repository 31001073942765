<template>
  <a-button
    @click="getMobileVerify(mobile)"
    :class="disable ? 'disableBtn' : 'ableButton'"
    :disabled="disable"
    :type="btnType"
    size="large"
    >{{ buttonTxt }}</a-button
  >
</template>

<script>
import { message } from "ant-design-vue";
import { LoginAPI } from "@/api/login.js";
export default {
  data() {
    return {
      buttonTxt: "发送验证码",
      disable: false,
      clock: null,
    };
  },
  props: {
    countDown: {
      type: Number,
      default: 60,
    },
    mobile: {
      type: String,
      default: "",
    },
    btnType: {
      type: String,
      default: "primary",
    },
    modelType: {
      type: String,
      default: "",
    },
  },
  methods: {
    //倒计时
    getMobileVerify(mobile) {
      console.log(mobile);
      if (!this.checkMobile(mobile)) {
        console.log("dafsasdfasd");
        message.error("请输入正确的手机号");
        return;
      }

      if (this.disable) {
        return;
      }

      if (this.modelType === "登录") {
        LoginAPI.getCheckCode(this.mobile).then((res) => {
          console.log(res);
          if (res.success) {
            this.$emit("onSendMessage");
            this.disable = true;
            let countDown = this.countDown;
            this.clock = setInterval(() => {
              countDown--;
              this.buttonTxt = `已发送${countDown}S`;
              if (countDown == 0) {
                this.resetButton();
              }
            }, 1000);
          }
          console.log("fasdfasdfasdfasdfasdfasdf");
          return false;
        });
      } else {
        this.disable = true;
        let countDown = this.countDown;
        this.clock = setInterval(() => {
          countDown--;
          this.buttonTxt = `已发送${countDown}S`;
          if (countDown == 0) {
            this.resetButton();
          }
        }, 1000);
        this.$emit("onSendMessage");
      }
    },
    //重置按钮
    resetButton() {
      clearInterval(this.clock);
      this.disable = false;
      this.buttonTxt = "重新获取";
    },
    //校验手机格式
    checkMobile(mobile) {
      return RegExp(/^(?:(?:\+|00)86)?1[3-9]\d{9}$/).test(mobile);
    },
  },
};
</script>

export default {
    methods: {
        loginRoute(){
            this.$router.push('/login')
        },

        registerRoute(){
            this.$router.push('/register')
        },

        HomeRoute(){
            this.$router.push('/')
        },

        CheckRoute(){
            this.$router.push('/check')
        },

        OrderRoute(){
            this.$router.push('/order')
        },

        EpRoute(){
            this.$router.push('/editsPassword')
        },

        RouteLoginBack(){
            this.$router.go(-3)
        },

        RouteBack(){
            this.$router.go(-2)
        },


    }  
}
import axios from "@/config/http";
export const LoginAPI = {
  // 获取基本信息
  login(params) {
    return axios.post(`/trade/login`, params);
  },
  loginCode(params) {
    return axios.post(`/trade/loginCode`, params);
  },
  loginout() {
    return axios.post(`/trade/logout`);
  },
  register(params) {
    return axios.post(`/trade`, params);
  },
  editPasswoord(params) {
    return axios.put(`/trade/resetPwd`, params);
  },
  async getCode(phone) {
    return await axios.get(`/trade/getCode/${phone}`);
  },
  async getRegisterCode(phone) {
    return await axios.get(`/trade/register/getCode/${phone}`);
  },

  // 检查手机号有没有注册
  async getCheckCode(phone) {
    return await axios.get(`/trade/check/getCode/${phone}`);
  },
};
